

@import 'variables';


a {color: $brand-color;}


/* ================================================================================================ 
	places styles 
================================================================================================ */
body,* {
	/*font-family: Arial !important;*/
}

.dashboard-table {
	width: 100%; margin: 16px 0;
}

.dashboard-table th {
	font-weight: 400;
}

.dashboard-table tbody tr {
	
}

.dashboard-table tbody tr:nth-child(2n+1) {
	/* odd */
	background-color: #FAFAFA;
}

.dashboard-table tbody td {
	line-height: 24px;
}

.dashboard-table-cell-id {
	width: 5%;
	text-align: left;
}

.dashboard-table-cell {
	text-align: left;
	width: 80%;
}
.dashboard-table-cell-name {
	text-align: left;
	width: 40%;
}

.dashboard-table-cell-baseUrl {
	text-align: left;
	width: 35%;
}

.dashboard-table-cell-jsp_file {
	text-align: left;
	width: 20%;
}



.dashboard-table-cell-actions {
	width: 109px; text-align: right;
}

.dt-custom-filter {
	width: 100%;

	.chosen-choices,
	.chosen-choices input {
		min-height: 35px;

		li.search-choice {
			margin: 8px 0 0 5px;
			max-width: 71%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	select {
		height: 35px;
	}
}






.device-table-cell-id {
	width: 5%;
	text-align: left;
}

.device-table-cell-identifier {
	width: 20%;
	text-align: left;
}

.device-table-cell {
	width: 80%;
	text-align: left;
}

.device-table-cell-dashboard {
	text-align: left;
	width: 40%;
}

.device-table-cell-viewkey {
	text-align: left;
	width: 10%;
}


.dashboard-table-cell-actions {
	width: 109px; text-align: right;
}










.dataTables_paginate * {
	margin-right: 16px;
}

.dataTables_length select {
	display: inline;
}

.dataTables_length {
	width: 50%; display: inline-block;
}

.dataTables_filter {
	display: inline-block;
}

.datatables_info_and_page {
	float:left;
	clear:left;
	width: 100%;
}

/* widgets table */
.widget-table {
	width: 100%; margin: 16px 0;
}

.widget-table th {
	font-weight: 400;
}

.widget-table tbody tr {
	
}

.widget-table tbody tr:nth-child(2n+1) {
	/* odd */
	background-color: #FAFAFA;
}

.widget-table tbody td {
	line-height: 24px;
}

.widget-table-cell-id {
	width: 5%;
	text-align: left;
}

.widget-table-cell-name {
	text-align: left;
	width: 35%;
}

.widget-table-cell-package {
	text-align: left;
	width: 15%;
}

.widget-table-cell-page {
	text-align: left;
	width: 10%;
}

.widget-table-cell-dashboard {
	text-align: left;
	width: 25%;
}

.widget-table-cell-actions {
	width: 109px; text-align: right;
}


/* property table */
.property-table-cell-id {
	width: 5%;
	text-align: left;
}

.property-table-cell-key {
	width: 35%;
	text-align: left;
}

.property-table-cell-value {
	width: 40%;
	max-width: 100px;
	text-align: left;
	overflow: auto;
	max-height: 100px; /* greift nicht */
}

.property-table-cell-sort_index {
	width: 10%;
	text-align: left;
}

.property-table-cell-actions {
	width: 109px; text-align: right;
}

/* users table */
.users-table-cell-id {
	width: 5%;
	text-align: left;
}

.users-table-cell-name {
	width: 45%;
	text-align: left;
}

.user-table-cell-active {
	width: 5%;
	text-align: left;
}

.users-table-cell-actions {
	width: 10%; text-align: right;
}


.property-delete, .property-delete div {
	width:16px;
	vertical-align:middle;
	height:16px; 
}
.property-delete div {
	background-image: url('../img/ico/open/delete-item.png'); 
	background-size:cover;
	background-position:fixed;
	cursor:pointer;
}
/*
#widget-table_length {
	
}

#widget-table_filter, #dashboard-table_filter, #property-table_filter, #users-table_filter {
	width: 50%;
	text-align: right;
	padding-bottom: 10px;
}

#widget-table_info, #dashboard-table_info, #property-table_info, #users-table_info {
	margin-top: 10px;
}*/

#dashboardSelect, #dashboardSelectCopy, #keySelect, #packageSelect, #jspSelect, #newsSelect {
	width: 100%;
}


.previewContainer {
    position: absolute;
    height: 0px;
}


.previewImage {
    position: relative;
    width: 200px;
    left: 100px;
    top: -35px;
}

.previewImage .thumb img {
    max-height: 130px;
}





.previewNewsContainer {
    /*position: absolute;*/
    height: 0px;
}

.previewNewsImage {
    /*position: relative;*/
    width: 100px;
    /*left: 175px;*/
}

.previewNewsImage .thumb img {
    max-height: 60px;
    max-width: 100px;
}

label.with_shortdescription {
	margin-bottom: 0px;
	font-weight: bold;
}

.label_shortdescription {
	margin-bottom: 5px;
    color: #5f5f5f;
    font-size: 14px;
}

.hide { 
	display: none; 
}

table.dataTable tbody tr.selected {
	background-color: #B0BED9;
}

select {
	width: 254px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	&.small {
		width: 134px;
	}
	&.medium {
		width: 254px;
	}
	&.large {
		width: 434px;
	}
	&.expand {
		width: 100%;
	}
}

label.error {
    font-weight: 700;
    font-size: 12px;
    clear: both;
    position: relative;
    margin-top: -6px;
    color: #c00000 !important;
    float: none;
    display: block;
}

